import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, PrevNext, Text, Br } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v2' next='/dz/v4' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第三卷-观众生业缘品"
    }}>{`第三卷 观众生业缘品`}</h3>
    <Text mdxType="Text">
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="yē" zi="耶" mdxType="Zu" />
  <Zu yin="fū" zi="夫" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zào" zi="造" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="chā" zi="差" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="yìnɡ" zi="应" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="yún" zi="云" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="？" zi="？" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="dā" zi="答" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="tǔ" zi="土" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="声" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="pì" zi="辟" mdxType="Zu" />
  <Zu yin="zhī" zi="支" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fēi" zi="非" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="yē" zi="耶" mdxType="Zu" />
  <Zu yin="fū" zi="夫" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="chónɡ" zi="重" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="qiě" zi="且" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="dā" zi="答" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="cū" zi="粗" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="nán" zi="南" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="xiào" zi="孝" mdxType="Zu" />
  <Zu yin="fù" zi="父" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="shā" zi="杀" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="xiě" zi="血" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǐ" zi="毁" mdxType="Zu" />
  <Zu yin="bànɡ" zi="谤" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="qīn" zi="侵" mdxType="Zu" />
  <Zu yin="sǔn" zi="损" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="zhù" zi="住" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="diàn" zi="玷" mdxType="Zu" />
  <Zu yin="wū" zi="污" mdxType="Zu" />
  <Zu yin="sēnɡ" zi="僧" mdxType="Zu" />
  <Zu yin="ní" zi="尼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="qié" zi="伽" mdxType="Zu" />
  <Zu yin="lán" zi="蓝" mdxType="Zu" />
  <Zu yin="nèi" zi="内" mdxType="Zu" />
  <Zu yin="zì" zi="恣" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="yín" zi="淫" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="shā" zi="杀" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bèi" zi="辈" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wěi" zi="伪" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="fēi" zi="非" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="pò" zi="破" mdxType="Zu" />
  <Zu yin="yònɡ" zi="用" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="zhù" zi="住" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qī" zi="欺" mdxType="Zu" />
  <Zu yin="kuánɡ" zi="诳" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="违" mdxType="Zu" />
  <Zu yin="bèi" zi="背" mdxType="Zu" />
  <Zu yin="jiè" zi="戒" mdxType="Zu" />
  <Zu yin="lǜ" zi="律" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="zào" zi="造" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bèi" zi="辈" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tōu" zi="偷" mdxType="Zu" />
  <Zu yin="qiè" zi="窃" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="zhù" zi="住" mdxType="Zu" />
  <Zu yin="cái" zi="财" mdxType="Zu" />
  <Zu yin="wù" zi="物" mdxType="Zu" />
  <Zu yin="ɡǔ" zi="谷" mdxType="Zu" />
  <Zu yin="mǐ" zi="米" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="fu" zi="服" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="wù" zi="物" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="zàn" zi="暂" mdxType="Zu" />
  <Zu yin="tínɡ" zi="停" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="yē" zi="耶" mdxType="Zu" />
  <Zu yin="fū" zi="夫" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="chónɡ" zi="重" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="yún" zi="云" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="？" zi="？" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wéi" zi="围" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="nèi" zi="内" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="zi" zi="字" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="chénɡ" zi="城" mdxType="Zu" />
  <Zu yin="zhōu" zi="周" mdxType="Zu" />
  <Zu yin="zā" zi="匝" mdxType="Zu" />
  <Zu yin="bá" zi="八" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="yú" zi="余" mdxType="Zu" />
  <Zu yin="lǐ" zi="里" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="chénɡ" zi="城" mdxType="Zu" />
  <Zu yin="chún" zi="纯" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡāo" zi="高" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="lǐ" zi="里" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chénɡ" zi="城" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="jù" zi="聚" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="kōnɡ" zi="空" mdxType="Zu" />
  <Zu yin="quē" zi="缺" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="chénɡ" zi="城" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="相" mdxType="Zu" />
  <Zu yin="lián" zi="连" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dú" zi="独" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zhōu" zi="周" mdxType="Zu" />
  <Zu yin="zā" zi="匝" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="bā" zi="八" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="lǐ" zi="里" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="qiánɡ" zi="墙" mdxType="Zu" />
  <Zu yin="ɡāo" zi="高" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="lǐ" zi="里" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xī" zi="悉" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wéi" zi="围" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="chè" zi="彻" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="chè" zi="彻" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="shé" zi="蛇" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="ɡǒu" zi="狗" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tǔ" zi="吐" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="chí" zi="驰" mdxType="Zu" />
  <Zu yin="zhú" zi="逐" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="qiánɡ" zi="墙" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dōnɡ" zi="东" mdxType="Zu" />
  <Zu yin="xi" zi="西" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="zǒu" zi="走" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="biàn" zi="遍" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="lǐ" zi="里" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="biàn" zi="遍" mdxType="Zu" />
  <Zu yin="wò" zi="卧" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bèi" zi="备" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="kǒu" zi="口" mdxType="Zu" />
  <Zu yin="yá" zi="牙" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="jiàn" zi="剑" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǎn" zi="眼" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="diàn" zi="电" mdxType="Zu" />
  <Zu yin="ɡuānɡ" zi="光" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǒu" zi="手" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="zhǎo" zi="爪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tuō" zi="拖" mdxType="Zu" />
  <Zu yin="zhuài" zi="拽" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="zhí" zi="执" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="jǐ" zi="戟" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="中" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="中" mdxType="Zu" />
  <Zu yin="kǒu" zi="口" mdxType="Zu" />
  <Zu yin="bí" zi="鼻" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="中" mdxType="Zu" />
  <Zu yin="fù" zi="腹" mdxType="Zu" />
  <Zu yin="bèi" zi="背" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="pāo" zi="抛" mdxType="Zu" />
  <Zu yin="kōnɡ" zi="空" mdxType="Zu" />
  <Zu yin="fān" zi="翻" mdxType="Zu" />
  <Zu yin="jiē" zi="接" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhì" zi="置" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="yīnɡ" zi="鹰" mdxType="Zu" />
  <Zu yin="dàn" zi="啖" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="mù" zi="目" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="shé" zi="蛇" mdxType="Zu" />
  <Zu yin="jiǎo" zi="绞" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="jǐnɡ" zi="颈" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="zhī" zi="肢" mdxType="Zu" />
  <Zu yin="jiē" zi="节" mdxType="Zu" />
  <Zu yin="nèi" zi="内" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xī" zi="悉" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="chánɡ" zi="长" mdxType="Zu" />
  <Zu yin="dìnɡ" zi="钉" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="shé" zi="舌" mdxType="Zu" />
  <Zu yin="ɡēnɡ" zi="耕" mdxType="Zu" />
  <Zu yin="lí" zi="犁" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chōu" zi="抽" mdxType="Zu" />
  <Zu yin="chánɡ" zi="肠" mdxType="Zu" />
  <Zu yin="cuò" zi="剉" mdxType="Zu" />
  <Zu yin="zhǎn" zi="斩" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yánɡ" zi="烊" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="ɡuàn" zi="灌" mdxType="Zu" />
  <Zu yin="kǒu" zi="口" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rè" zi="热" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="chán" zi="缠" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dònɡ" zi="动" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="qī" zi="期" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="huài" zi="坏" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jì" zi="寄" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="huài" zi="坏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="jì" zi="寄" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="huài" zi="坏" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǎn" zi="辗" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="相" mdxType="Zu" />
  <Zu yin="jì" zi="寄" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="hòu" zi="后" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huán" zi="还" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="？" zi="？" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="jué" zi="绝" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="qì" zi="器" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="bànɡ" zi="棒" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yīnɡ" zi="鹰" mdxType="Zu" />
  <Zu yin="shé" zi="蛇" mdxType="Zu" />
  <Zu yin="lánɡ" zi="狼" mdxType="Zu" />
  <Zu yin="quǎn" zi="犬" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duì" zi="碓" mdxType="Zu" />
  <Zu yin="mó" zi="磨" mdxType="Zu" />
  <Zu yin="jù" zi="锯" mdxType="Zu" />
  <Zu yin="záo" zi="凿" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cuò" zi="剉" mdxType="Zu" />
  <Zu yin="zhuó" zi="斫" mdxType="Zu" />
  <Zu yin="huò" zi="镬" mdxType="Zu" />
  <Zu yin="tānɡ" zi="汤" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wǎnɡ" zi="网" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="shénɡ" zi="绳" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="lǘ" zi="驴" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="mǎ" zi="马" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="ɡé" zi="革" mdxType="Zu" />
  <Zu yin="luò" zi="络" mdxType="Zu" />
  <Zu yin="shǒu" zi="首" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rè" zi="热" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="jiāo" zi="浇" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jī" zi="饥" mdxType="Zu" />
  <Zu yin="tūn" zi="吞" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wán" zi="丸" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="kě" zi="渴" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="zhī" zi="汁" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cónɡ" zi="从" mdxType="Zu" />
  <Zu yin="nián" zi="年" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="nuó" zi="那" mdxType="Zu" />
  <Zu yin="yóu" zi="由" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="chǔ" zi="楚" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="相" mdxType="Zu" />
  <Zu yin="lián" zi="连" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="duàn" zi="断" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiānɡ" zi="羌" mdxType="Zu" />
  <Zu yin="hú" zi="胡" mdxType="Zu" />
  <Zu yin="yí" zi="夷" mdxType="Zu" />
  <Zu yin="dí" zi="狄" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lǎo" zi="老" mdxType="Zu" />
  <Zu yin="yòu" zi="幼" mdxType="Zu" />
  <Zu yin="ɡuì" zi="贵" mdxType="Zu" />
  <Zu yin="jiàn" zi="贱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="gui" zi="鬼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="xíng" zi="行" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xī" zi="悉" mdxType="Zu" />
  <Zu yin="tónɡ" zi="同" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cónɡ" zi="从" mdxType="Zu" />
  <Zu yin="chū" zi="初" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="jiān" zi="间" mdxType="Zu" />
  <Zu yin="zàn" zi="暂" mdxType="Zu" />
  <Zu yin="zhù" zi="住" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chú" zi="除" mdxType="Zu" />
  <Zu yin="fēi" zi="非" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="lián" zi="连" mdxType="Zu" />
  <Zu yin="mián" zi="绵" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cū" zi="粗" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="ɡuǎnɡ" zi="广" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="qì" zi="器" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="yē" zi="耶" mdxType="Zu" />
  <Zu yin="fū" zi="夫" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chóu" zi="愁" mdxType="Zu" />
  <Zu yin="yōu" zi="忧" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dǐnɡ" zi="顶" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v2' next='/dz/v4' mdxType="PrevNext" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      